<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="panel panel-default">
                    <el-form :model="createCourseForm" :rules="rules" ref="createCourseForm" class="demo-ruleForm">
                        <el-form-item label="选择类型" prop="type">
                            <el-radio-group v-model="createCourseForm.type">
                                <el-radio-button :label="courseItem.id" v-for="courseItem in types" :key="courseItem.id" :disabled="(courseItem.id === 3 || courseItem.id === 4) ? true : false">
                                    <i class="iconfont" v-html="courseItem.icon"></i>
                                    <div class="course-type">{{courseItem.course_type_name}}</div>
                                    <div class="course-intro">{{courseItem.course_type_content}}</div>
                                </el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="课程名称" prop="name" class="other-item">
                            <el-input v-model="createCourseForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="课程分类" prop="class" class="other-item">
                            <el-cascader v-model="createCourseForm.class" style="width: 300px" size="medium" :props="keyObj" :options="courseClass" ref="specObj" clearable placeholder="请选择分类"></el-cascader>
                        </el-form-item>
<!--                        <el-form-item label="课程模式" class="other-item" v-if="isMultipe">-->
<!--                            <el-select v-model="modeId">-->
<!--                                <el-option v-for="(item, key) in modeList" :label="item.modeName" :value="item.modeId"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
<!--                        <el-form-item label="教师课程" prop="parentId" v-if="modeId && isMultipe">-->
<!--                            <el-select v-model="createCourseForm.parentId" placeholder="请选择">-->
<!--                                <el-option v-for="item in courses" :key="item.course_id" :label="item.course_name" :value="item.course_id"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
                        <el-form-item label="课程简介" prop="desc" class="other-item">
                            <el-input type="textarea" v-model="createCourseForm.desc"></el-input>
                        </el-form-item>
                        <el-form-item label="课程排序" prop="sort" class="other-item">
                            <el-popover placement="right" trigger="click">
                                <div class="tooltipTxt">排序数值在1~255之间，数值越小，课程展示越靠前</div>
                                <el-input v-model="createCourseForm.sort" slot="reference" class="c-input-width-80"></el-input>
                            </el-popover>
                        </el-form-item>
                        <el-form-item label="课程封面" prop="cover" class="other-item">
                            <el-upload
                                    class="cover-uploader"
                                    action="admin/uploadImg"
                                    :headers="headerParam"
                                    :show-file-list="false"
                                    :on-success="handleCoverSuccess"
                                    :before-upload="beforeCoverUpload">
                                <el-button size="small" type="primary" v-if="imageUrl">重新上传</el-button>
                                <el-button size="small" type="primary" v-else>点击上传</el-button>
                                <div class="cover">
                                    <img v-if="imageUrl" :src="imageUrl">
                                </div>
                                <span class="item-txt-tip">*建议使用宽178像素*高100像素的图片</span>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="课程首页" prop="index" class="other-item">
                            <el-button type="primary" size="small" @click="editCourse">进入编辑</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm('createCourseForm')">确定</el-button>
                            <el-button @click="resetForm('createCourseForm')">重置</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import _ from "underscore";
    export default {
        name: "DialogEditCourse",
        created() {
        },
        props: ['data', 'id', 'types'],
        computed: {
            ...mapGetters(['getLesson', 'initData']),
            isMultipe() {
                if (this.createCourseForm.type === 1) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        watch: {
            property: {
                immediate: true,
                handler()
                {
                    this.getTeacherCourses();
                    this.headerParam.Authorization = localStorage.getItem('token');
                    this.imageUrl = this.data.cover;
                    this.createCourseForm= this.data;
                    this.createCourseForm.cover = this.createCourseForm.src;
                    if (this.createCourseForm.parentId) {
                        this.modeId = 1;
                    }
                    this.getCourseClass();
                }
            },
            modeId() {
                if (this.modeId === 0) {
                    this.createCourseForm.parentId = '';
                }
            }

        },
        data() {
            let checkCover = (rule, value, callback) => {
                if (this.imageUrl === '') {
                    callback(new Error('请上传课程封面！'));
                }  else {
                    callback();
                }
            };
            let checkNumber = (rule, value, callback) => {
                if (isNaN(parseInt(value))) {
                    callback(new Error('请输入数字！'));
                } else {
                    callback();
                }
            }
            return {
                //是否显示弹窗
                dialogCreateCourse: false,
                //创建课程表单数据
                createCourseForm: {
                    type: 1,
                    name: '',
                    class: [],
                    desc: '',
                    cover: '',
                    parentId: '',
                    sort: 255
                },
                modeList: [
                    {modeId: 0, modeName: '教师课程'},
                    {modeId: 1, modeName: '学生课程'}
                ],
                courses: [],
                modeId: 0,
                rules: {
                    name: [
                        { required: true, message: '请输入课程名称', trigger: 'blur' },
                        { min: 3, max: 60, message: '长度在 3 到 30 个字符', trigger: 'blur' }
                    ],
                    class: [
                        { required: true, message: '请选择课程分类', trigger: 'change' }
                    ],
                    desc: [
                        { required: true, message: '请填写课程简介', trigger: 'blur' }
                    ],
                    cover: [
                        { validator: checkCover, trigger: 'change' }
                    ],
                    parentId: [
                        { required: true, message: '请选择教师课程', trigger: 'change'}
                    ],
                    sort: [
                        { validator: checkNumber, trigger: 'blur'}
                    ]
                },
                courseClass: [],
                keyObj: {
                    label: 'rc_name',
                    value: 'rc_id'
                },
                imageUrl: '',
                headerParam: {
                    Authorization: null
                }
            }
        },
        methods: {
            //教师课程列表
            getTeacherCourses() {
                this.$http.axiosGetBy(this.$api.courses, { parent_id: 0}, (res) => {
                    if (res.code === 200) {
                        this.courses = res.list;
                    } else {
                        console.log(res.msg);
                    }
                })
            },
            //课程分类
            getCourseClass() {
                this.$http.axiosGetBy(this.$api.resourceClassList, {type: 3}, (res) => {
                    if (res.code === 200) {
                        this.courseClass = this.getCourseClassData(res.list);
                    }
                })
            },
            getCourseClassData(data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].children.length === 0) {
                        delete data[i].children;
                    } else {
                        this.getCourseClassData(data[i].children)
                    }
                }
                return data;
            },
            //上传
            handleCoverSuccess(res, file) {
                if (res.code === 200) {
                    this.imageUrl = res.data.url;
                    this.createCourseForm.cover = res.data.src;
                } else {
                    this.$message({
                        type: 'error',
                        message: '图片上传失败，请稍后再试~'
                    });
                }
            },
            beforeCoverUpload(file) {
                /* console.log(file);
                const isJPG = file.type === 'image/jpeg'; */
                const isLt2M = file.size / 1024 / 1024 < 2;

                /* if (!isJPG) {
                    this.$message.error('上传课程封面图片只能是 JPG 格式!');
                } */
                if (!isLt2M) {
                    this.$message.error('上传课程封面图片大小不能超过 2MB!');
                }
                return isLt2M;
            },
            editCourse(){
                this.$router.push({
                    path:"/admin/courseIndexAdd/index",
                    query:{
                        course_id:this.id
                    }
                })
            },
            //提交
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        let courseInfo = {
                            course_name: this.createCourseForm.name,
                            course_desc: this.createCourseForm.desc,
                            course_cover: this.createCourseForm.cover,
                            parent_id: this.createCourseForm.parentId ? this.createCourseForm.parentId : 0,
                            course_type: this.createCourseForm.type,
                            resource_class_id: this.createCourseForm.class[this.createCourseForm.class.length - 1],
                            sort: parseInt(this.createCourseForm.sort)
                        }
                        this.$store.dispatch('setLessonContent', {...this.getLesson, ...courseInfo});
                        this.$router.push('/admin/courseManage/qualityCourseCreate');
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }
        }
    }
</script>


<style lang="scss">
</style>

