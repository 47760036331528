<template>
    <div class="index-container">
        <div class="course-content">
            <div class="select-wrapper">
                <div class="select-left">
                    <el-cascader v-model="courseClassValue" style="width: 300px" size="medium" :props="keyObj" :options="courseClass" ref="specObj" clearable placeholder="请选择分类"
                                 @change="changeCourseClass"></el-cascader>
                </div>
                <div class="select-btn">
                    <el-button type="primary" @click="createCourseBtn">创建课程</el-button>
                </div>
            </div>
            <div class="type-wrapper" style="margin: 10px 0;">
                <div class="type-name">课程类型：</div>
                <div class="type-list">
                    <div class="type-item" :class="{current:typeCurrent === 0}"  @click="toggleTypeCur(0)">全部</div>
                    <div class="type-item" :class="{current:typeCurrent === courseItem.id}" v-for="courseItem in courseTypeList" :key="courseItem.id" @click="toggleTypeCur(courseItem.id)">{{courseItem.course_type_name}}</div>
                </div>
            </div>
            <!--课程列表-->
            <el-table :data="courseTableData" border style="width: 100%; flex: 1;" height="1%" size="medium" :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa'}" class="table-content" :cell-style="{fontSize: '12px',color: '#333'}">
                <el-table-column label="封面" width="210" align="center">
                    <template slot-scope="scope">
                        <img :src="scope.row.course_cover" alt="">
                    </template>
                </el-table-column>
                <el-table-column label="课程类型" width="100" align="center">
                    <template slot-scope="scope">
                        <span :title="scope.row.course_type">{{ scope.row.course_type }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="课程名称" width="200">
                    <template slot-scope="scope">
                        <span class="text-overflow-2" :title="scope.row.course_title">{{ scope.row.course_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="课程简介">
                    <template slot-scope="scope">
                        <span class="text-overflow-2" :title="scope.row.course_content">{{ scope.row.course_desc }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="负责人" width="100" align="center">
                    <template slot-scope="scope">
                        <span>{{scope.row.admin_name}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="更新时间" width="100" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.update_time }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="150" align="center">
                    <template slot-scope="scope">
                        <el-button size="small" type="primary" @click="editCourse(scope.row, scope.$index)">编辑</el-button>
                        <el-button size="small" type="danger" @click="deleteRow(scope.$index, courseTableData, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="pages-right" :current-page="currentPage" layout="prev, pager, next, jumper" :total="totalPages" @current-change="handleCurrentChange">
            </el-pagination>
            <!--创建课程弹窗-->
            <DialogCreateCourse :types="courseTypeList" ref="createCourseDialog"></DialogCreateCourse>
            <!--编辑课程弹窗-->
            <el-dialog :title="editCourseTitle" :visible.sync="editStatus" :close-on-click-modal="false" top="2vh" class="upload-resources-dialog create-course-dialog">
                <dialog-edit-course :data.sync="data" :types="courseTypeList" :id="courseId" v-if="editStatus"></dialog-edit-course>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import DialogCreateCourse from 'components/createCourse/DialogCreateCourse'
    import DialogEditCourse from "../../components/createCourse/DialogEditCourse";
    import _ from "underscore";
    export default {
        name: "CourseManage",
        components: {
            DialogCreateCourse,
            DialogEditCourse,
        },
        created() {
            this.getCourses();
            this.getCourseType();
            this.getCourseClass();
        },
        data() {
            return {
                //课程分类数据
                courseClassValue: [],
                courseClass: [],
                keyObj: {
                    label: 'rc_name',
                    value: 'rc_id'
                },
                //课程类型数据
                courseTypeList: [],
                //选择课程类型标识
                typeCurrent: 0,
                //课程列表数据
                courseTableData: [],
                //分页字段
                page: 1,
                currentPage: 1,
                totalPages: 0,
                editStatus: false,
                data: null,
                editCourseTitle: '',
                courseId: 0,
                courseClassId: 0,
            }
        },
        methods: {
            //课程分类
            getCourseClass() {
                this.$http.axiosGetBy(this.$api.resourceClassList, {type: 3}, (res) => {
                    if (res.code === 200) {
                        this.courseClass = this.getCourseClassData(res.list);
                    }
                })
            },
            getCourseClassData(data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].children.length === 0) {
                        delete data[i].children;
                    } else {
                        this.getCourseClassData(data[i].children)
                    }
                }
                return data;
            },
            //点击创建课程按钮，显示弹窗
            createCourseBtn() {
                this.$refs.createCourseDialog.dialogCreateCourse = true;
            },
            toggleTypeCur(id) {
                this.typeCurrent = id;
                this.getCourses();
            },
            changeCourseClass() {
                this.courseClassId = this.courseClassValue[this.courseClassValue.length-1];
                this.getCourses();
            },
            getCourses() {
                let _this = this;
                let params = {
                    page: this.page,
                    limit: 10
                }
                if (this.typeCurrent) {
                    params.type = _this.typeCurrent;
                }
                if (this.courseClassId) {
                    params.classId = this.courseClassId;
                }
                this.$http.axiosGetBy(this.$api.courses, params, (res) => {
                    if (res.code === 200) {
                        _this.totalPages = res.total;
                        _this.courseTableData = res.list;
                    }
                }, (err) => {
                    console.log(err);
                });
            },
            //编辑课程
            editCourse(rows,index) {
                let params = { id: rows.course_id };
                let _this = this;
                this.$http.axiosGetBy(this.$api.courseContent, params, (res) => {
                    if (res.code === 200) {
                        let data = res.data;
                        let tmp = {
                            type: data.course_type,
                            name: data.course_name,
                            class: data.resource_class_id,
                            desc: data.course_desc,
                            cover: data.course_cover,
                            src: data.src,
                            parentId: data.parent_id,
                            sort: data.sort
                        };
                        _this.data = tmp;
                        _this.courseId = rows.course_id;
                        _this.editCourseTitle = '编辑课程：' + rows.course_name;
                        //过滤课程下的资源
                        let reslist = data.reslist;
                        let papers = data.papers;
                        let projects = data.projects;
                        let course_content = data.course_content;
                        // console.log('course_content', course_content);
                        let chapters = this.$common.filterResource(course_content, reslist, papers,projects);
                        // vuex
                        let courseInfo = {
                            course_id: data.course_id,
                            course_name: data.course_name,
                            course_desc: data.course_desc,
                            course_cover: data.course_cover,
                            resource_class_id: data.resource_class_id,
                            parent_id: data.parent_id,
                            course_type: data.course_type,
                            course_content: chapters,
                            sort: data.sort
                        }
                        this.$store.dispatch('setLessonContent', courseInfo);

                        _this.editStatus = !this.editStatus;

                    }

                }, (err) => {
                    console.log(err);
                })
            },
            //删除课程
            deleteRow(index, rows, row) {
                this.$confirm(`此操作将永久删除该课程：${row.course_name}, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.axiosGetBy(this.$api.deletecourse, { id: row.course_id}, (res) => {
                        if (res.code === 200) {
                            this.$message({
                                type: 'success',
                                message: res.msg,
                                duration: 1000,
                                onClose: function () {
                                    rows.splice(index, 1);
                                }
                            });
                        } else {
                            this.$message({
                                type: 'warning',
                                message: res.msg
                            });
                        }
                    }, (err) => {
                        console.log(err);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

            },
            //翻页
            handleCurrentChange(val) {
                this.page = val;
                this.getCourses();
            },
            getCourseType() {
                this.$http.axiosGet(this.$api.courserType, (res) => {
                    if (res.code === 200) {
                        this.courseTypeList = res.list;
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .course-content {
        padding: 20px;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        ::v-deep .el-table--border td:first-child .cell {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 178px;
            height: 100px;
            margin: 0 auto;
            padding: 0;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    .select-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .type-wrapper {
        margin-top: 10px;
        display: flex;
        .type-name {
            width: 100px;
            margin-top: 10px;
            line-height: 30px;
            color: #000;
        }
        .type-list {
            .type-item {
                display: inline-block;
                padding: 4px 10px;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
                border-radius: 4px;
                margin: 10px 10px 0 0;
                font-size: 14px;
                border: 1px solid transparent;
                cursor: pointer;
                color: #666;
                transition: all .3s ease-in;
                &.current {
                    color: #409eff;
                    background: #ecf5ff;
                    border: 1px solid #d9ecff;
                }
                &:hover {
                    color: #409eff;
                }
            }
        }
    }
    .table-content {
        .img-thumb {
            width: 60px;
            height: 60px;
            overflow: hidden;
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    .video-dialog {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.5);
        z-index: 9;
        .close-icon {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 30px;
            width: 30px;
            height: 30px;
            line-height: 1;
            background: rgba(0,0,0,.7);
            color: #fff;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                color: #409EFF;
            }
        }
        .video-content {
            width: 50%;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 3px solid #111;
            video {
                outline: none;
                display: block;
            }
        }
    }
    .upload-btn {
        position: relative;
        input {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }
</style>