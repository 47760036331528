<template>
    <div>
        <el-dialog title="创建课程" top="2vh" :visible.sync="dialogCreateCourse" @close="closeDialog" :close-on-click-modal="false" class="upload-resources-dialog create-course-dialog">
            <div class="container">
                <div class="row">
                    <div class="panel panel-default">
                        <el-form :model="createCourseForm" :rules="rules" ref="createCourseForm" class="demo-ruleForm">
                            <el-form-item class="course-type-radio" label="选择类型" prop="type">
                                <el-radio-group v-model="createCourseForm.type">
                                    <el-radio-button :label="courseItem.id" v-for="courseItem in types" :key="courseItem.id" :disabled="(courseItem.id === 3 || courseItem.id === 4) ? true : false">
                                        <i class="iconfont" v-html="courseItem.icon"></i>
                                        <div class="course-type">{{courseItem.course_type_name}}</div>
                                        <div class="course-intro">{{courseItem.course_type_content}}</div>
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="课程名称" prop="name" class="other-item">
                                <el-input v-model="createCourseForm.name"></el-input>
                            </el-form-item>
                            <el-form-item label="课程分类" prop="class" class="other-item">
                                <el-cascader v-model="createCourseForm.class" style="width: 300px" size="medium" :props="keyObj" :options="courseClass" ref="specObj" clearable placeholder="请选择分类"></el-cascader>
                            </el-form-item>
<!--                            <el-form-item label="课程模式" class="other-item" v-if="isMultipe">-->
<!--                                <el-select v-model="modeId">-->
<!--                                    <el-option v-for="(item, key) in modeList" :label="item.modeName" :value="item.modeId"></el-option>-->
<!--                                </el-select>-->
<!--                            </el-form-item>-->
<!--                            <el-form-item label="教师课程" prop="parentId" v-if="modeId && isMultipe">-->
<!--                                <el-select v-model="createCourseForm.parentId" placeholder="请选择">-->
<!--                                    <el-option v-for="item in courses" :key="item.course_id" :label="item.course_name" :value="item.course_id"></el-option>-->
<!--                                </el-select>-->
<!--                            </el-form-item>-->
                            <el-form-item label="课程简介" prop="desc" class="other-item">
                                <el-input type="textarea" v-model="createCourseForm.desc"></el-input>
                            </el-form-item>
                            <el-form-item label="课程排序" prop="sort" class="other-item">
                                <el-popover placement="right" trigger="click">
                                    <div class="tooltipTxt">排序数值在1~255之间，数值越小，课程展示越靠前</div>
                                    <el-input v-model="createCourseForm.sort" slot="reference" class="c-input-width-80"></el-input>
                                </el-popover>
                            </el-form-item>
                            <el-form-item label="课程封面" prop="cover" class="other-item">
                                <el-upload
                                        class="cover-uploader"
                                        action="admin/uploadImg"
                                        :headers="headerParam"
                                        :show-file-list="false"
                                        :on-success="handleCoverSuccess"
                                        :before-upload="beforeCoverUpload">
                                    <el-button size="small" type="primary" v-if="imageUrl">重新上传</el-button>
                                    <el-button size="small" type="primary" v-else>点击上传</el-button>
                                    <div class="cover" v-if="imageUrl">
                                        <img :src="imageUrl">
                                    </div>
                                    <span class="item-txt-tip">*建议使用宽356像素*高200像素的图片</span>
                                </el-upload>
                            </el-form-item>
                            <el-form-item label="课程首页" prop="index" class="other-item">
                                <el-button type="primary" size="small" @click="editCourse('createCourseForm')">进入编辑</el-button>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="submitForm('createCourseForm')">立即创建</el-button>
                                <el-button @click="resetForm('createCourseForm')">重置</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "DialogCreateCourse",
        created() {
            this.headerParam.Authorization = localStorage.getItem('token');
            this.getCourseClass();
        },
        computed: {
            isMultipe() {
                if (this.createCourseForm.type === 1) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        watch: {
            property: {
                immediate: true,
                handler()
                {
                    this.getTeacherCourses()
                }
            },
            modeId() {
                if (this.modeId === 0) {
                    this.createCourseForm.parentId = '';
                }
            }

        },
        props: ['types'],
        data() {
            let checkCover = (rule, value, callback) => {
                if (this.imageUrl === '') {
                    callback(new Error('请上传课程封面！'));
                }  else {
                    callback();
                }
            };
            let checkNumber = (rule, value, callback) => {
                if (isNaN(parseInt(value))) {
                    callback(new Error('请填写数字'));
                } else {
                    callback();
                }
            }
            return {
                //是否显示弹窗
                dialogCreateCourse: false,
                isShowCs: false,
                courses: [],
                modeId: 0,
                modeList: [
                    {modeId: 0, modeName: '教师课程'},
                    {modeId: 1, modeName: '学生课程'}
                ],
                //创建课程表单数据
                createCourseForm: {
                    type: 1,
                    name: '',
                    class: [],
                    desc: '',
                    cover: '',
                    parentId: '',
                    sort: 255
                },
                rules: {
                    name: [
                        { required: true, message: '请输入课程名称', trigger: 'blur' },
                        { min: 3, max: 60, message: '长度在 3 到 30 个字符', trigger: 'blur' }
                    ],
                    class: [
                        { required: true, message: '请选择课程分类', trigger: 'change' }
                    ],
                    desc: [
                        { required: true, message: '请填写课程简介', trigger: 'blur' }
                    ],
                    parentId: [
                        { required: true, message: '请选择教师课程', trigger: 'change' }
                    ],
                    cover: [
                        { validator: checkCover, trigger: 'change' }
                    ],
                    sort: [
                        { validator: checkNumber, trigger: 'blur' }
                    ]
                },
                courseClass: [],
                keyObj: {
                    label: 'rc_name',
                    value: 'rc_id'
                },
                imageUrl: '',
                headerParam: {
                    Authorization: null
                },
            }
        },
        methods: {
            //教师课程列表
            getTeacherCourses() {
                this.$http.axiosGetBy(this.$api.courses, { parent_id: 0}, (res) => {
                    if (res.code === 200) {
                        this.courses = res.list;
                    } else {
                        console.log(res.msg);
                    }
                })
            },
            //课程分类
            getCourseClass() {
                this.$http.axiosGetBy(this.$api.resourceClassList, {type: 3}, (res) => {
                    if (res.code === 200) {
                        this.courseClass = this.getCourseClassData(res.list);
                    }
                })
            },
            getCourseClassData(data) {
                for (let i = 0; i < data.length; i++) {
                    if (data[i].children.length === 0) {
                        delete data[i].children;
                    } else {
                        this.getCourseClassData(data[i].children)
                    }
                }
                return data;
            },
            //上传
            handleCoverSuccess(res, file) {
                if (res.code === 200) {
                    this.imageUrl = res.data.url;
                    this.createCourseForm.cover = res.data.src;
                } else {
                    this.$message({
                        type: 'error',
                        message: '图片上传失败，请稍后再试~'
                    });
                }
            },
            beforeCoverUpload(file) {
                /* console.log(file);
                const isJPG = file.type === 'image/jpeg'; */
                const isLt2M = file.size / 1024 / 1024 < 2;

                /* if (!isJPG) {
                    this.$message.error('上传课程封面图片只能是 JPG 格式!');
                } */
                if (!isLt2M) {
                    this.$message.error('上传课程封面图片大小不能超过 2MB!');
                }
                return isLt2M;
            },
            editCourse(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('setLessonContent', {
                            course_id: 0,
                            course_name: this.createCourseForm.name,
                            course_desc: this.createCourseForm.desc,
                            course_cover: this.createCourseForm.cover,
                            resource_class_id: this.createCourseForm.class[this.createCourseForm.class.length -1],
                            parent_id: this.createCourseForm.parentId ? this.createCourseForm.parentId : 0,
                            course_type: this.createCourseForm.type,
                            sort: this.createCourseForm.sort
                        });
                        this.$router.push("/admin/courseIndexAdd/index")
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //提交
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('setLessonContent', {
                            course_id: 0,
                            course_name: this.createCourseForm.name,
                            course_desc: this.createCourseForm.desc,
                            course_cover: this.createCourseForm.cover,
                            resource_class_id: this.createCourseForm.class[this.createCourseForm.class.length -1],
                            parent_id: this.createCourseForm.parentId ? this.createCourseForm.parentId : 0,
                            course_type: this.createCourseForm.type,
                            sort: this.createCourseForm.sort
                        });
                        this.$router.push('/admin/courseManage/qualityCourseCreate');
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            closeDialog() {
                this.resetForm('createCourseForm');
            }
        }
    }
</script>

<style lang="scss">
    @import "../../assets/style/iconfont.css";
    .c-input-width-80 {
        width: 80px;
    }
    .tooltipTxt { color: #909399 }
    .addCourseForm {
        .course-type-radio {
            .el-form-item__label { width: 100%; }
        }
    }
    .create-course-dialog {
        .el-dialog {
            .el-dialog__header {
                border-bottom: 1px solid #e1e1e1;
                padding: 20px 50px;
            }
            .el-dialog__body {
                padding: 30px 50px;
            }
        }
    }
    .container {
        margin: auto;
        min-height: 400px;
        padding-left: 10px;
        padding-right: 10px;
        .row {
            margin-left: -10px;
            margin-right: -10px;
            .panel {
                margin-bottom: 20px;
                background-color: #fff;
                border-radius: 4px;
                .el-form {
                    .el-form-item:first-child {
                        .el-form-item__label {
                        }
                        .el-radio-group {
                            width: 100%;
                            .el-radio-button {
                                position: relative;
                                min-height: 1px;

                                border: 2px solid transparent;
                                border-radius: 10px;
                                .el-radio-button__inner {
                                    padding: 35px 15px;
                                    width: 100%;
                                    height: 250px;
                                    border: 2px solid transparent;
                                    text-align: center;
                                    background-color: #f4f6f8;
                                    border-radius: 10px;
                                    cursor: pointer;
                                    &:hover {
                                        color: #616161;
                                    }
                                    i {
                                        font-size: 47px;
                                    }
                                    .course-type {
                                        padding: 20px 0;
                                        font-size: 16px;
                                        border-bottom: 1px solid #e1e1e1;
                                    }
                                    .course-intro {
                                        padding-top: 20px;
                                        font-size: 12px;
                                        white-space: initial;
                                        line-height: 1.42857143;
                                    }
                                }
                                &:hover {
                                    border: 2px solid #409EFF;
                                }
                            }
                            .is-disabled {
                                &:hover {
                                    border: 2px solid transparent !important;
                                }
                                .el-radio-button__inner {
                                    &:hover {
                                        color: #C0C4CC !important;
                                        cursor: not-allowed;
                                    }
                                }
                            }
                            .el-radio-button:last-child {
                                margin-right: 0;
                            }
                            .is-active {
                                &:hover {
                                    border: 2px solid transparent;
                                }
                                .el-radio-button__inner {
                                    width: 100%;
                                    background-color: #409EFF;
                                    color: #fff;
                                    &:hover {
                                        color: #fff;
                                    }
                                    .course-type {
                                        border-bottom: 1px solid #fff;
                                    }
                                }
                            }
                        }
                    }
                    .el-form-item {
                        .el-form-item__label {
                            position: relative;
                            text-align: left;
                            padding-left: 15px;
                            font-size: 16px;
                            line-height: 22px;
                            margin-right: 20px;
                            &:before {
                                content: "";
                                position: absolute;
                                left: 0;
                                top: 3px;
                                width: 4px;
                                height: 16px;
                                background-color: #409EFF;
                            }
                        }
                    }
                }
                .other-item {
                    display: flex;
                    align-items: center;
                    .el-form-item__content {
                        flex: 1;
                        textarea {
                            height: 114px;
                            resize: none;
                        }
                        .cover-uploader {
                            .el-upload {
                                display: flex;
                                align-items: center;
                                .el-button {
                                    margin-right: 20px;
                                }
                                .cover {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 178px;
                                    height: 100px;
                                    border: 1px solid #DCDFE6;
                                    img {
                                        max-width: 100%;
                                        max-height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .panel-default {
                border-color: #e4ecf3;
                min-height: 500px;
            }
        }
    }

    .item-txt-tip {
        color: #F56C6C;
        margin-left: 10px;
        font-size: 12px;
    }

    @media (min-width: 768px) {
        .create-course-dialog {
            .el-dialog {
                width: 740px;
            }
        }
    }

    @media (min-width: 992px) {
        .container .row .panel .el-form .el-form-item:first-child .el-radio-group .el-radio-button {
            margin: 20px 16px 30px 0;
        }
        .create-course-dialog {
            .el-dialog {
                width: 960px;
                .el-radio-button {
                    width: 23%;
                    float: left;
                }
            }
        }
    }

    @media (min-width: 1200px) {
        .create-course-dialog {
            .el-dialog {
                width: 1160px;
            }
        }
        .container .row .panel .el-form .el-form-item:first-child .el-radio-group .el-radio-button {
            margin: 20px 22px 30px 0;
        }
    }
</style>
